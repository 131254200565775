.fs-14 {
  font-size: 0.875rem !important;
}

.fs-12 {
  font-size: 0.75rem;
}

.fs-15 {
  font-size: 0.938rem !important ;
}

.fs-22{
  font-size: 1.375rem;
}

.fs-19{
  font-size: 1.188rem;
}