$primary-purple: #7047eb;
$secondary-royal: #5932ea;
$white: #ffffff;
$very-pale-blue: #f9fbff;
$link-blue: #127bff;

$blue: #2c7be5 !default;
$gray-600: #748194 !default;
$green: #008767 !default; 
$light-green:#E2FFEF !default;
$cyan: #27bcfd !default;
$yellow: #FF947A !default;
$light-yellow:#FFF4DE !default;
$red: #FF947A !default;
$light-red: #FFE2E5!default;
$gray-100: #f9fafd !default;
$gray-1100: #0b1727 !default;

$primary: $blue !default;
$success: $green !default;
$warning: $yellow !default;
$danger: $red !default;


.badge-subtle-primary {
  border: 1px solid $primary;
  background-color: $primary;
  color: $primary;
}

.badge-subtle-success {
  border: 1px solid $success;
  background-color: $light-green;
  color: $success !important;
}

.badge-subtle-warning {
  border: 1px solid $warning;
  background-color: $light-yellow;
  color: $warning !important;
}

.badge-subtle-danger {
  border: 1px solid $red;
  background-color: $light-red;
  color: $danger !important;
}

:root {
  --primary-purple: #{$primary-purple};
  --secondary-royal: #{$secondary-royal};
  --white: #{$white};
  --very-pale-blue: #{$very-pale-blue};
  --link-blue: #{$link-blue};
}
