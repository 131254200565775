@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Outfit:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import './assets/scss/_variables.scss';

.app-layout {
  display: flex;
  min-height: 100vh;
}

.sideNav {
  background-color: #ffffff;
  color: black;
  flex-shrink: 0;
  align-self: stretch;
}

.content-area {
  flex-grow: 1;
  min-height: 100vh;
  min-width: 0;
}

.sideNav ul {
  list-style-type: none;
  padding: 0;
}

.sideNav ul li {
  text-align: left;
}

p {
  margin-bottom: 0rem !important;
  font-family: 'Poppins', sans-serif !important;
  color: #000000;
  font-weight: 500;
}

span {
  font-family: 'Poppins', sans-serif;
  margin-bottom: 0 !important;
}

.mt-36 {
  margin-top: 2.25rem;
}

.pe-16 {
  padding-right: 1rem;
}

.px-14 {
  padding-left: 0.875rem;
}

.navbar .nav-link {
  border-radius: 0.5rem;
  color: #000000;
  width: 100%;
}

.navbar .nav-link.active {
  background-color: var(--secondary-royal) !important;
  color: white;
}

.navbar {
  display: block !important;
}

.custom-tabs {
  font-weight: 600 !important;
  font-size: 1.188rem !important;
}

.custom-tabs .nav-link:not(.active) {
  color: #748194 !important;
}

.tabs-margin .nav-item {
  margin-right: auto !important;
}

.color {
  border: 1px solid #eeeeee !important;
}

.h-46 {
  height: 2.875rem;
}

.pt-8 {
  padding-top: 0.5rem;
}

.circle {
  width: 2.625rem;
  height: 2.625rem;
}

@media (min-width: 23.438rem) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 90rem !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.pe-67 {
  padding-right: 4.1rem;
}

.page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.proceed-login {
  background-color: #ffffff;
  border-radius: 1.5rem;
  box-shadow: 0px 10px 60px 0px #e2ecf980;
}

.login-container {
  width: 100%;
  max-width: 28rem;
  background-color: #ffffff;
  border-radius: 1.5rem;
  box-shadow: 0px 10px 60px 0px #e2ecf980;
}

.login-text {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.375rem;
  color: #000000;
  line-height: 2rem;
  padding-left: 2rem;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.email-text,
.pw-text {
  color: #121217 !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 0.875rem;
  font-weight: 500;
  padding-bottom: 0.1rem;
}

.color-star {
  color: #f53d6b;
}

input {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 0.3125rem;
  font-size: 1rem;
  padding: 0.2rem;
}

.button-styling {
  width: 100%;
  max-width: 20rem;
  margin: 1.5rem auto 0;
  border-radius: 0.5rem;
  background-color: #1eb386;
  border: none;
  color: white;
}

.button-teal {
  border: none;
  color: white;
  border-radius: 0.5rem;
  background-color: #1eb386;
}

.error {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 0.3125rem;
}

.input-wrapper {
  position: relative;
  width: 100%;
}

.password-input {
  width: 100%;
  padding-left: 0.9rem;
}

.forgot-password {
  color: #757272;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

.icons {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #888;
}

.icons:hover {
  color: #000;
}

input[type='password']::-ms-reveal {
  display: none;
}

.input-size {
  width: 30rem !important;
}

.invoice-whole-container {
  background-color: #fafbff;
  width: 100%;
  overflow-y: hidden;
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .invoice-whole-container {
    overflow-x: auto;
    white-space: nowrap;
  }
  .in-containerpage {
    width: 744px;
  }
  .note-wrap {
    overflow-wrap: break-word;
    white-space: normal;
    word-break: break-word;
  }
}

@media (min-width: 1367px) {
  .in-containerpage {
    width: 744px;
  }
}

.box-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  border-radius: 1.875rem;
  background-color: #ffffff;
  box-shadow: 0px 10px 60px 0px #e2ecf980;
}

.mb-70 {
  margin-bottom: 70px;
}

.table-headings {
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  color: #b5b7c0 !important;
  padding-bottom: 1rem !important;
  padding-left: 1rem !important;
}

.count-container {
  margin-top: 2.375rem;
  box-shadow: 0px 10px 60px 0px #e2ecf980;
  background-color: #ffffff;
  border-radius: 1rem;
  height: 151px;
}

.process-icon {
  background-color: #fff4de;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.passed-icon {
  background: linear-gradient(#d3ffe7, #effff6);
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.failed-icon {
  background: #ffe2e5;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clock-icon {
  color: #ff947a;
}

.tick-icon {
  color: #00873e;
}

.wrong-icon {
  color: #fa5a7d;
}

.count-head {
  color: #acacac;
}

.count-number-style {
  font-family: 'Poppins', sans-serif;
  color: #333333;
  font-size: 2rem;
}

.card-all-return-requests {
  margin-top: 1.5rem;
  box-shadow: 0px 10px 60px 0px #e2ecf980;
  background-color: #ffffff;
  border-radius: 1.875rem;
}

.button-indigo {
  background-color: var(--secondary-royal);
  color: #ffff;
  border-radius: 0.5rem;
  border: none;
  padding: 0.5rem 1.5rem;
}

.button-indigo-height {
  height: 2.5rem !important;
}

.pointer {
  cursor: pointer;
}

#serial-color {
  color: #127bff !important;
}

.OrgChangeDropDown {
  background-color: white !important;
  color: black !important;
  border-color: white !important;
  display: flex !important;
  align-items: center;
  justify-content: start;
  width: 10rem;
  padding: 0 !important;
  font-family: 'Poppins', sans-serif !important;
}

.OrgChangeDropDown.custom-arrow::after {
  content: none !important;
}

.branch-change-dropdown {
  background-color: #fafbff !important;
  color: black !important;
  border-color: #fafbff !important;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

.sort-drop-down {
  background-color: var(--very-pale-blue) !important;
  color: black !important;
  border-color: white !important;
  display: flex !important;
  align-items: center;
  padding: 1 !important;
  &.custom-arrow::after {
    color: #757575 !important;
  }
}

.dropdown-item.selected-item {
  background-color: #5932ea !important;
  color: #ffffff !important;
  font-family: 'Poppins', sans-serif !important;
}

.dropdown-item {
  font-family: 'Poppins', sans-serif;
}

.dropdown-item:hover {
  background-color: #127bff !important;
  color: #ffffff !important;
}

.disabled-dropdown {
  background-color: #e9ecef !important;
  color: black !important;
  border: 1px solid #d1d1db !important;
  width: 23.75rem;
  height: 3rem;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  &.custom-arrow::after {
    color: #8a8aa3 !important;
  }
  text-align: left;
  padding-left: 0.5rem;
}

.custom-container {
  background-color: #ffffff;
  height: auto;
  border-radius: 1.875rem;
}

.custom-input {
  width: 25rem !important;
  padding-left: 1rem;
}

.pt-40 {
  padding-top: 2.5rem;
}

.custom-btn-outline {
  border-color: var(--secondary-royal) !important;
  color: var(--secondary-royal) !important;
}

.custom-btn-outline:hover {
  background-color: var(--secondary-royal) !important;
  color: #fff !important;
}

.pt-32 {
  padding-top: 2rem;
}

input:focus {
  border-color: var(--primary-purple);
  outline: none;
}

input.error {
  border-color: #dc3545;
  outline: none;
}

.pt-20 {
  padding-top: 1.25rem;
}

.select-vehicle-container {
  background-color: #ffffff;
  border-radius: 1.875rem;
}

.mt-10 {
  margin-top: 0.625rem;
}

.full-height {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.review-organisation-container {
  background-color: #ffffff;
  width: 33.438rem;
  height: 26.063rem;
  border-radius: 1.875rem;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.view-branches-container {
  background-color: #ffffff;
  width: 77rem;
  height: auto;
  border-radius: 1.875rem;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.edit-branches-container {
  background-color: #ffffff;
  width: 77rem;
  height: auto;
  border-radius: 1.875rem;
}

.create-branches-container {
  width: 35rem;
  min-height: 53.37rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-invite-dropdown {
  background-color: white !important;
  color: #6c6c89 !important;
  border-color: #d1d1db !important;
  width: 25%;
  text-align: left !important;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

.select-branches-outer-container {
  width: auto !important;
  height: auto !important;
  border-radius: 1rem !important;
  border: 1px solid #0000004d !important;
}

.select-branches-inner-container {
  width: 26.313rem !important;
  height: 8.5rem !important;
  border-radius: 1rem !important;
  border: 1px solid #0000004d !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;

  &.selected {
    border-color: var(--primary-purple) !important;
  }

  input[type='checkbox'] {
    accent-color: var(--primary-purple);
    margin-left: 1rem;
    width: 1.5rem;
    height: 1.5rem;
  }
}

.mt-32 {
  margin-top: 2rem;
}

.pb-32 {
  padding-bottom: 2rem;
}

.loc-form-container {
  width: 100%;
  max-width: 26.75rem;
  padding: 1.5rem;
  border-radius: 1.875rem;
  background-color: #fff;
}

.loc-input-container {
  display: flex;
  flex-direction: column;
}

.loc-input-field {
  width: 100%;
  height: 2.5rem;
  margin: 0.375rem 0;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  font-size: 1rem;
}

.input-field:focus {
  border-color: #1eb386;
}

.button-styling:hover {
  background-color: #17a773;
}

.loc-button-styling {
  width: 100%;
  max-width: 12.5rem;
  margin: 1.875rem auto 0.625rem auto;
  border-radius: 0.5rem;
  background-color: #1eb386;
  border: none;
  color: white;
}

.loc-input-field.error {
  border-color: #dc3545;
}

.error-text {
  color: #dc3545;
  font-size: 0.875rem;
}

.loc-input-field:focus {
  border-color: var(--primary-purple);
  outline: none;
}

.open-form-btn {
  width: 10.75rem;
  height: 2.5rem;
  font-size: 1rem;
  background-color: #ffffff;
  color: var(--secondary-royal);
  border-color: var(--secondary-royal);
  border-radius: 0.3125rem;
  cursor: pointer;
}

.user {
  margin-left: 24px;
  margin-top: 28.5px;
}

.open-form-btn:hover {
  background-color: var(--secondary-royal);
  color: #ffffff;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.user-name {
  margin-left: 20px;
  margin-right: 1210px;
  margin-bottom: 964px;
  margin-top: 190px;
}

.grayish-blue {
  color: #b5b7c0 !important;
}

.dark-grayish-blue {
  color: #667085 !important;
}

.rotate-up {
  transform: rotate(60deg);
}

.background-container {
  background-color: #ffffff;
}

.dropdown-container select {
  width: auto;
  font-family: 'Poppins', sans-serif;
  color: #000000;
  margin-left: 1.15rem;
  margin-top: 2.8rem;
}

.card-bg-color {
  background-color: #fdfdfd;
}

.amount-card-heading {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  color: #1a1919;
  font-size: 1.125rem;
}

.rupee-style {
  font-family: 'Inter', sans-serif !important;
  color: #01150c !important;
}

.white-container {
  background-color: var(--white);
}

.padding-heading {
  color: #000000;
  font-size: 1.4rem;
  font-family: 'Poppins', sans-serif;
}

.recent-services-head {
  font-size: 1.375rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #000000;
  padding-top: 2.0625rem;
  padding-left: 2.2rem;
}

.card-radius {
  border-radius: 0.5rem;
  box-shadow: 0px 8px 24px 0px #4545501a;
}

.services-card-radius {
  border-radius: 1.875rem;
  box-shadow: 0px 10px 60px 0px #e2ecf980;
}

.services-headers {
  color: #b5b7c0 !important;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  align-items: center;
}

.text-color {
  color: #656575;
}

.in-containerpage {
  padding: 2rem;
  min-height: 56.875rem;
  background-color: #ffffff;
  border: 0.0625rem solid #ffffff;
  font-family: 'Poppins', sans-serif;
  border-radius: 0.75rem;
}

.tobill {
  margin-top: 1.5rem;
}

.custom-table thead th {
  background-color: #fafafa;
  color: #000000 !important;
}

.custom-table tbody td {
  color: #000000 !important;
}

.custom-table td,
.custom-table th {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  font-family: 'Inter', sans-serif !important;
  font-size: 14px;
  font-weight: 500;
}

.table td,
.table th {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.table th {
  color: #b5b7c0 !important;
}

.table td {
  color: #292d32 !important;
}

.username {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 2.5625rem;
  font-family: 'Poppins', sans-serif;
  color: #000000;
}

.total-row td {
  padding: 5rem;
  font-size: 0.75rem;
  border: none;
  background-color: #f8f9fa;
}

.right-container {
  width: 25.9375rem;
  height: auto;
  padding: 1.5rem;
  margin-left: 1.25rem;
  border-radius: 1.25rem;
  background-color: #ffffff;
  font-family: 'Poppins', sans-serif;
}

.save-btn {
  margin-top: 1rem;
  width: 10.75rem;
  height: 2.5rem;
  font-size: 0.875rem;
  background-color: var(--secondary-royal);
  color: #ffffff;
  border-color: var(--secondary-royal);
  border-radius: 0.3125rem;
  cursor: pointer;
}

.save-btn2 {
  margin-top: 1rem;
  width: 8.375rem;
  height: 2.5rem;
  background-color: var(--secondary-royal);
  color: #ffffff;
  border-color: var(--secondary-royal);
  border-radius: 0.3125rem;
  cursor: pointer;
}

.save-btn:hover {
  background-color: #ffffff;
  color: var(--secondary-royal);
}

.hr {
  padding-top: 1rem;
}

@media print {
  .invoice-whole-container {
    transform: scale(0.95);
    transform-origin: center;
    width: 100%;
    height: auto;
  }
  .sideNav,
  .right-section {
    display: none;
  }
  .profile-user img {
    display: none !important;
  }
  .profile-user,
  .invoice-page,
  .left-section,
  .right-section {
    margin-top: 0 !important;
    padding: 0 !important;
  }
  @page {
    size: A4;
    margin: 0;
  }
}

.left-section,
.right-section {
  margin-top: 3rem;
}

.invoice-page {
  padding: 1rem;
}

.bill-container {
  padding: 1rem;
  border-radius: 0.5rem;
}

.change-button {
  background-color: white;
  font-weight: 0.75rem;
  color: #127bff;
  border: #127bff;
  border-radius: 0.25rem;
  padding: 0.3125rem 0.625rem;
  cursor: pointer;
  text-decoration: none;
}

.change-button:hover {
  background-color: #0e6eb8;
  color: white;
}

.dark-slate-gray {
  color: #333843;
}

.credit-status-settled {
  color: green;
  border-color: green;
  background-color: lightgreen;
}

.credit-status-unsettled {
  color: #ff947a;
  background-color: #fff4de;
  border-color: #ff947a;
}

.new-return-tracker-container {
  margin-top: 2.5rem !important;
  box-shadow: 0px 10px 60px 0px #e2ecf980;
  background-color: #ffffff;
  border-radius: 1.875rem;
}

.bunker-color {
  color: #292d32;
}

.narrow-input {
  width: 80% !important;
}

.label-text {
  color: #121217 !important;
  font-size: 0.875rem !important;
  font-family: 'Poppins', sans-serif;
}

.show-user-name {
  font-family: 'Poppins', sans-serif;
  color: #000000;
}

.input-credit {
  width: auto;
  border: 1px solid #ccc;
  border-radius: 0.3125rem;
  font-size: 1rem;
  padding: 0.2rem;
}

.button-indigo:hover {
  background-color: #2f08bd;
}

.hr-color hr {
  border: 1px #eeeeee !important;
}

.icon-size {
  height: 1.5rem !important;
  width: 1.5rem !important;
}

.icon-size-profile {
  height: 1.25rem !important;
  width: 1.25rem !important;
  margin-right: 0.875rem !important;
}

.gap-cards-vehicle {
  padding-right: 4.5rem;
}

.error-message {
  color: #dc3545;
  font-size: 0.875rem;
}

.note-styling {
  font-family: 'Poppins', sans-serif;
  font-size: 0.875rem;
  line-height: 1.5rem;
  text-align: left;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.limited-text {
  white-space: nowrap;
  max-width: 10ch;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fs-22 {
  font-size: 1.375rem;
}

.set-password-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.p-12 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.button-okay {
  background-color: #1eb386;
  color: #ffffff;
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem;
  padding: 0.75rem 1rem !important;
  border: 'none';
  border-radius: 1.5rem;
  cursor: 'pointer';
}
.modal {
  background-color: rgba(0, 0, 0, 0.5);
  width: 52.0625rem;
}

.printing {
  font-weight: 300;
  font-family: 'Poppins', sans-serif;
  color: #127bff;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.status-tab {
  width: auto;
  height: auto;
  margin-left: 1.3125rem;
  margin-right: 1.3125rem;
  border: 0.01875rem solid #000000;
  font-weight: 400;
  margin-top: 2.468125rem;
  overflow: hidden;
}

.data {
  font-weight: 500;
}
.details-tab {
  margin-left: 1.2rem;
  margin-right: 1.4rem;
  padding-bottom: 2.31rem;
}

.data-info {
  color: #2d3748;
}
.details-tab .data,
.data-info {
  padding-top: 1rem;
}

.created-style {
  padding-left: 2rem;
  padding-top: 1.875rem;
}

.status-style {
  padding-top: 1.875rem;
  padding-right: 7.375rem;
}

.top-box {
  background-color: #f8faff;
}

.icon-spacing {
  margin-right: 1rem;
}

.heading-icon {
  padding-left: 1.0625rem !important;
  margin-top: 1.25rem !important;
  height: 3.125rem !important;
  width: 3.125rem !important;
  margin-bottom: 0.5rem !important;
}

.icon-text-container {
  display: flex;
  flex-direction: column;
}

@media print {
  .card-all-return-requests,
  .count-container,
  .profile-container {
    visibility: hidden;
    overflow: hidden;
  }
  .modal-content,
  .modal-content * {
    visibility: visible;
  }
  .modal-content {
    transform: scale(0.95);
    transform-origin: top center;
  }
  @page {
    size: A4;
    margin: 0;
  }
}

.ls-data {
  gap: 5rem;
}

.tyre-logo img {
  height: 2.8125rem;
  width: 2.8125rem;
}
.modal-content {
  border-radius: 1.875rem !important;
}
.alerts-bg-container {
  background-color: #ffffff;
  box-shadow: 0px 10px 60px 0px #e2ecf980;
  margin-top: 3rem !important;
  border-radius: 1.875rem;
}

.bulk-alerts-heading {
  font-family: 'Poppins', sans-serif;
  font-size: 1.375rem;
  font-weight: 600;
  text-align: left;
  color: #000000;
}

.strong-blue {
  color: #0090ce;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.button-search {
  padding: 1.9rem !important;
}

.dim-gray {
  color: #757575;
}

.mx-19 {
  margin-right: 19px;
  margin-left: 19px;
}

.mt-15 {
  margin-top: 15px;
}

.muted-grayish-blue {
  color: #6c6c89;
  font-family: 'Inter', sans-serif;
}

.sub-menu {
  list-style: none;
  padding-left: 2rem;
  margin: 0;
  position: relative;
}

.sub-menu::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0;
  border-left: 1px solid #000000;
}

.sub-menu li {
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
}

.sub-nav-link {
  text-decoration: none;
  color: #000000;
  font-size: 12px;
  padding-left: 1.5rem;
  transition: color 0.3s ease;
  font-family: 'Outfit', sans-serif;
}

.sub-nav-link:hover {
  color: #000;
}

.sub-nav-link.active {
  color: var(--secondary-royal);
  font-weight: normal;
  font-size: 12px;
}

.background-pale-blue {
  background-color: var(--very-pale-blue) !important;
}

.search {
  background-color: var(--very-pale-blue) !important;
  border: transparent !important;
  color: #7e7e7e !important;
}

input.search:focus {
  border: transparent !important;
  box-shadow: none;
}

.icon-color {
  color: #7e7e7e !important;
}

.btn-no-style {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.link-blue {
  color: var(--link-blue) !important;
}

.background-billing {
  padding: 16px 24px 16px 24px;
  border-radius: 30px;
  background: #ffffff;
  box-shadow: 0px 10px 60px 0px #e2ecf980;
}

.title-box {
  font-family: 'Poppins', sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
  text-align: left;
  color: #000000;
}

.vehicle-type {
  font-family: 'Poppins', sans-serif;
  line-height: 18px;
  display: flex;
  border-radius: 10px;
}

.mt-21 {
  margin-top: 1.3125rem;
}

.billing-container {
  display: flex;
  gap: 16px;
  height: 100%;
  width: 100%;
}

.box-cash-transactions {
  width: 100%;
  height: 100%;
  padding: 24px 16px;
  border-radius: 6px 6px 6px 6px;
  gap: 25px;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px 0px #00000012;
  box-shadow: 0px 7px 14px 0px #4145581a;
}

.box-name {
  width: 196px;
  height: 92px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  opacity: 1;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 8px;
}

.box-cash-transactions-gap {
  margin-top: 24px;
  margin-bottom: 24px;
}

.second-container {
  padding: 24px;
  margin-top: 46px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 10px 60px 0px #e2ecf980;
}

.box-summary-report {
  height: auto;
}

.summary-report {
  width: 188px;
  height: 33px;
  font-family: 'Poppins', sans-serif;
  font-size: 22px;
  font-weight: 600;
  border-top-left-radius: 40px;
  border-top: 40px;
  padding-right: 40px;
  line-height: 33px;
  letter-spacing: -1%;
  margin-top: 40px;
}

.dropdown-select {
  border: none;
  font-size: 14px;
  color: #3d3c42;
  padding: 0 8px;
  cursor: pointer;
  position: relative;
  background: #f9fbff !important;
}

.download-report-button {
  height: 40px;
  padding: 8px 24px;
  gap: 10px;
  border-radius: 8px 8px 8px 8px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: white;
  border: none;
  cursor: pointer;
  background: #5932ea;
}

.download-report-button:hover {
  background-color: #4527a0;
}

.box-element p {
  font-family: 'Inter', sans-serif;
  font-weight: bold;
}

.react-datepicker {
  position: absolute !important;
  top: calc(100% + 8px);
  right: 0px;
  z-index: 1000;
  background: white;
  max-width: calc(100vw - 24px);
}

.react-datepicker__month-container {
  width: 100%;
}

.user-profile-container {
  margin-top: 2.25rem;
  border-radius: 1.875rem;
  box-shadow: 0px 10px 60px 0px #e2ecf980;
}

.settings-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  opacity: 1;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 8px;
}

.form-section {
  width: 100%;
  margin-bottom: 20px;
}

.image-upload-container {
  text-align: center;
  width: 100%;
}

.upload-button {
  background-color: #5932ea !important;
  color: white;
  padding: 6px 24px;
  border: none;
  border-radius: 6.2rem;
  cursor: pointer;
}

.upload-button:hover {
  background-color: #180f8d;
}

.profile-heading {
  font-family: 'Poppins', sans-serif;
  font-size: 0.875rem;
  font-weight: 500 !important;
  color: #121217;
}

.mt-75 {
  margin-top: 4.6875rem !important;
}

.form-control {
  font-family: 'Poppins', sans-serif;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  color: black !important;
}

.edit-access-whole-container {
  background-color: #fafbff;
  width: 100%;
  overflow-y: hidden;
}

.calendar-dropdown {
  position: absolute;
  top: 130%;
  right: 0;
  z-index: 1000;
  background: #fff;
  border: 1px solid #aeaeae;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: max-content;
  font-size: 0.8rem;
  color: black;
}
 
.dropdown-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
 
.range-buttons {
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 8px;
  margin-bottom: 10px;
}
 
.range-buttons button {
  padding: 6px 12px;
  font-size: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9fbff;
  cursor: pointer;
  transition: all 0.2s;
}
 
.range-buttons button:hover {
  background-color: #007bff;
  color: white;
}
 
.datepicker-container {
  display: flex;
  justify-content: center;
}
 
.range-buttons button.selected {
  background-color: #216ba5;
  color: white;
  border-color: #216ba5;
}

.vehicles-heading {
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: #000000;
}

.sub-heading {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  color: #000000;
}

.vehicle-button {
  background-color: #1eb386;
  border: none;
  padding: 12px 115px 12px 115px;
  gap: 8px;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: white;
  font-weight: 600;
  line-height: 19.6px;
}

.vehicle-input {
  width: 380px !important;
  height: 48px !important;
  padding: 12px 48px 12px 16px !important;
}
