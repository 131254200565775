@use 'variables' as *;

.form-check-input {
  border: none;

  &:checked {
    opacity: 1;
    background-color: $primary-purple !important;
    border: none;
  }

  &:focus {
    border-color: $primary-purple !important;
    outline: none;
    box-shadow: none;
  }
}
