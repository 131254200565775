.page-container-org {
  display: flex;
  justify-content: center;
  align-items: center;
}

.organization-container {
  padding: 1.5rem;
  border-radius: 1.875rem;
  border-color: black;
  border-width: 10px;
  box-shadow: 0px 10px 60px 0px #e2ecf980;
  background-color: #ffffff;
}

.org-placeholder-text {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.hi-user-text {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.375rem;
  color: #000000;
}

.profile-whole-container {
  margin-top: 3rem;
  padding: 1rem;
}

.profile-top-text {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #000000;
  padding-bottom: 1rem;
  padding-left: 2.78125rem;
  padding-right: 3.78125rem;
}

.image-upload-container {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.image-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background-color: #f0f0f0;
  border-radius: 50%;
  margin: 0 auto 10px auto;
}

.icon-container {
  position: relative;
  width: 60px;
  height: 60px;
  background-color: #f0f0f0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.camera-icon {
  font-size: 2em;
}

.upload-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-button-styling {
  background-color: #2b60af;
  color: white;
  font-size: 0.875rem;
  padding: 6px 24px;
  border: none;
  border-radius: 1rem;
  cursor: pointer;
  align-items: center;
}

.upload-button-styling:hover {
  background-color: #1068eb;
}

.image-round-box {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.organization-form {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 3.34375rem;
  padding-right: 3.34375rem;
}

.business-name-text {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  color: #121217;
  padding-bottom: 0.1rem;
}
.color-star {
  color: red;
  padding-left: 0.5rem;
}

label {
  margin-bottom: 0.4rem;
  font-weight: bold;
}

input,
select {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  font-size: 1rem;
  padding: 0.5rem;
  padding-left: 1rem !important;
}

.no-of-locations-container {
  margin-top: 1rem;
}

.number-of-locations-text {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  color: #121217;
  padding-bottom: 0.1rem;
}

.next-button-styling {
  width: 80%;
  max-width: 20rem;
  margin-top: 2rem;
  border-radius: 0.5rem;
  background-color: #1eb386;
  border: none;
  color: white;
}

.next-button-styling:hover {
  background-color: #12976f;
}
